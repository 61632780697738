<template>
	<div class="page_bl">
		<el-form class="form_bl" label-width="100px">
			
			<div class="big_tit" style="margin:0">客户及线路</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="客户和线路" style="width:1000px">
					<el-cascader class="el_inner_width" @change="shipper_bl_change" v-model="shipper_bl.rel" placeholder="请选择" :options="shipper_bl.data" filterable></el-cascader>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="名称/距离" style="width:1000px">
					<el-input class="el_inner_width" v-model="shipper_bl.cargo_name+' / '+shipper_bl.distance+'公里'" disabled clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地详情" style="width:1000px">
					<el-input class="el_inner_width" v-model="shipper_bl.case_addr.total_text" disabled clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地详情" style="width:1000px">
					<el-input class="el_inner_width" v-model="shipper_bl.aim_addr.total_text" disabled clearable></el-input>
				</el-form-item>
			</div>
			

			<div class="big_tit">重量体积</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物重量(吨)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="cargo_weight.num" :placeholder="cargo_weight.is_need_weight_text" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物体积(方)" style="width:588px">
					<el-input class="el_inner_width" type="number" v-model="cargo_volume.num" :placeholder="cargo_volume.is_need_volume_text" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装卸货地日期</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货日期" style="width:488px">
					<el-date-picker
						v-model="form.case_date"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="卸货日期" style="width:488px">
					<el-date-picker
						v-model="form.aim_date"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
			</div>


			<!-- <div class="big_tit">指定车主</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车主电话" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="truck_owner.tel" placeholder="不填表示不指定" clearable>
						<el-button @click="truck_owner_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车主信息" style="width:588px">
					<el-input class="el_inner_width" v-model="truck_owner.name" :disabled="true" clearable>
						<el-button @click="truck_owner_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div> -->


			<div class="big_tit">其他信息 (运费信息说明: 如果不填运费则只能进行招投标, 如果填写运费可以直接抢单也可以指定也可以招投标)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运费(元)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="form.freight_total" placeholder="请输入运费信息" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车型要求" style="width:588px">
					<el-cascader class="el_inner_width" v-model="form.need_truck_type" placeholder="非必填" :options="truck_type" filterable></el-cascader>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游单号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.group1_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" style="width:947px" label="运单备注">
					<el-input v-model="form.mark" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
		</el-form>


		<div class="bottom_btns">
			<el-button type="primary" @click="sub">提交订单</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>
	</div>
</template>
<script>
	import {regionData,CodeToText,TextToCode} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	export default {
		data() {
			return {

				//表单
				form: {
					cargo_type:'普货',//货物性质(默认)
					case_date:null,//装货日期
					aim_date:null,//卸货日期
					freight_total:'',//运费
					need_truck_type:null,//车型要求
					out_trade_num:'',//上游运单编号
					group1_num:'',//组编号1(用户自定义打组)
					mark:'',//备注
				},

				//货主线路列表
				shipper_bl:{
					rel:null,//选择结果
					data:[],//联动主数据
					original_data:{},//原始数据
					cargo_name:"",//货物名称
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					distance:'',//运输距离(单位:公里)
					case_addr:{
						prov:'',
						city:'',
						county:'',
						addr:'',
						lat:0,
						lng:0,
						total_text:''
					},
					aim_addr:{
						prov:'',
						city:'',
						county:'',
						addr:'',
						lat:0,
						lng:0,
						total_text:''
					}
				},

				//货物重量
				cargo_weight:{
					num:'',//数值
					is_need_weight:'',//是否必填
					is_need_weight_text:'',//是否必填的placeholder
				},

				//货物体积
				cargo_volume:{
					num:'',//数值
					is_need_volume:'',//是否必填
					is_need_volume_text:'',//是否必填的placeholder
				},

				//城市联动数据
				cities:regionData,
				
				//车型
				truck_type:[],

				//承运人
				truck_owner:{
					tel:'',//承运人电话
					name:'',//承运人姓名
					user_num:'',//承运人用户编号
				},
			}
		},
		created() {

			//获取创建参数
			this.get_creat_para();

			//获取参数
			this.get_shipper_bl();
		},
		methods: {

			//提交
			sub(){

				let post_data={
					mod:'truck_tord_real_time',
					ctr:'cargo_add_admin',
					cargo_type:'普货'
				};

				if(!this.shipper_bl.cargo_name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物名称'
					});
					return;
				}else{
					post_data.shipper_num=this.shipper_bl.rel[0]
					post_data.cargo_name=this.shipper_bl.cargo_name.trim()
				}

				//货物重量
				if(this.cargo_weight.is_need_weight==1){//必填
					if(!(this.$my.check.is_num(this.cargo_weight.num)&&this.cargo_weight.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填吨数, 请输入货物吨数'
						});
						return;
					}else post_data.cargo_weight=this.cargo_weight.num
				}else if(this.cargo_weight.is_need_weight==2){//非必填
					if(this.$my.check.is_num(this.cargo_weight.num)&&this.cargo_weight.num>0){
						post_data.cargo_weight=this.cargo_weight.num
					}
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请选择路线'
					});
					return;
				}

				//货物体积
				if(this.cargo_volume.is_need_volume==1){//必填
					if(!(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填体积, 请输入货物体积'
						});
						return;
					}else post_data.cargo_volume=this.cargo_volume.num
				}else if(this.cargo_volume.is_need_volume==2){//非必填
					if(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0){
						post_data.cargo_volume=this.cargo_volume.num
					}
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请选择路线'
					});
					return;
				}

				if(!this.shipper_bl.case_addr.prov.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少发货省份'
					});
					return;
				}else post_data.case_prov=this.shipper_bl.case_addr.prov.trim()

				if(!this.shipper_bl.case_addr.city.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少发货城市'
					});
					return;
				}else post_data.case_city=this.shipper_bl.case_addr.city.trim()

				if(!this.shipper_bl.case_addr.county.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少发货县区'
					});
					return;
				}else post_data.case_county=this.shipper_bl.case_addr.county.trim()

				if(!this.shipper_bl.case_addr.addr.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少发货详细地址'
					});
					return;
				}else post_data.case_addr=this.shipper_bl.case_addr.addr.trim()

				if(this.$my.check.is_lat(this.shipper_bl.case_addr.lat)){//发货地纬度
					post_data.case_lat=this.shipper_bl.case_addr.lat
				}

				if(this.$my.check.is_lng(this.shipper_bl.case_addr.lng)){//发货地经度
					post_data.case_lng=this.shipper_bl.case_addr.lng
				}

				if(!this.shipper_bl.aim_addr.prov.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少到货省份'
					});
					return;
				}else post_data.aim_prov=this.shipper_bl.aim_addr.prov.trim()

				if(!this.shipper_bl.aim_addr.city.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少到货城市'
					});
					return;
				}else post_data.aim_city=this.shipper_bl.aim_addr.city.trim()

				if(!this.shipper_bl.aim_addr.county.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少到货县区'
					});
					return;
				}else post_data.aim_county=this.shipper_bl.aim_addr.county.trim()

				if(!this.shipper_bl.aim_addr.addr.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少到货详细地址'
					});
					return;
				}else post_data.aim_addr=this.shipper_bl.aim_addr.addr.trim()

				if(this.$my.check.is_lat(this.shipper_bl.aim_addr.lat)){//到货地纬度
					post_data.aim_lat=this.shipper_bl.aim_addr.lat
				}

				if(this.$my.check.is_lng(this.shipper_bl.aim_addr.lng)){//到货地经度
					post_data.aim_lng=this.shipper_bl.aim_addr.lng
				}

				//结算方式(1:按吨数,2:按方数,3:按趟)
				post_data.settlement_type=this.shipper_bl.settlement_type

				//是否回程(1:需要,2:不需要)
				post_data.is_need_return=this.shipper_bl.is_need_return

				//运输距离
				post_data.distance=this.shipper_bl.distance

				if(this.form.out_trade_num.trim()){//上游运单编号
					post_data.out_trade_num=this.form.out_trade_num.trim()
				}

				if(this.form.group1_num.trim()){//组编号1(用户自定义打组)
					post_data.group1_num=this.form.group1_num.trim()
				}

				if(this.form.mark.trim()){//备注
					post_data.mark=this.form.mark.trim()
				}

				if(this.truck_owner.user_num){//车主和运费

					//如果指定了车主,必须有运费
					if(!(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0)){//运费
						this.$my.other.msg({
							type:"warning",
							str:'如果指定了车主,必须填写运费'
						});
						return;
					}

					post_data.freight_total=this.form.freight_total
					post_data.truck_owner_num=this.truck_owner.user_num
				}else{

					if(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0){//运费
						post_data.freight_total=this.form.freight_total
					}
				}

				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{

						//如果指定了车主, 前往'待接单'
						if(post_data.truck_owner_num){
						
							//添加成功
							this.$my.other.confirm({
								content:"发货成功,是否前往待接单列表?",
								confirm:()=>{

									this.$router.push({
										path:'/pages/transit_real_time/tord_deal'
									});
								}
							});

						//如果没有指定车主, 前往'货源列表'
						}else{

							//添加成功
							this.$my.other.confirm({
								content:"发货成功,是否前往货源列表?",
								confirm:()=>{

									this.$router.push({
										path:'/pages/transit_real_time/cargo_list_admin'
									});
								}
							});
						}
					}
				});
			},

			//查找货主
			shipper_info_ser(){

				//格式判断
				if(!this.$my.check.is_tel(this.shipper_info.tel)){
					this.$my.other.msg({
						type:"warning",
						str:'货主电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'admin_list',
						tel:this.shipper_info.tel
					},
					callback:(data)=>{
						
						data=data.list[0];

						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}

						this.shipper_info.user_num=data.user_num
						this.shipper_info.info_text=data.name+' / '+data.company_name
					}
				});
			},

			//清空司机信息
			shipper_info_clear(){
				this.shipper_info.tel=''
				this.shipper_info.info_text=''
				this.shipper_info.user_num=''
			},

			//查找司机
			truck_owner_ser(){
				
				//格式判断
				if(!this.$my.check.is_tel(this.truck_owner.tel)){
					this.$my.other.msg({
						type:"warning",
						str:'司机电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_owner_info',
						truck_owner_tel:this.truck_owner.tel
					},
					callback:(data)=>{

						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}

						let user_info=this.$my.cache.get("user_info");
						if(user_info.user_num==data.user_num){
							this.$my.other.msg({
								type:"warning",
								str:'不能指定自己'
							});
							return;
						}

						this.truck_owner.user_num=data.user_num
						this.truck_owner.name=data.name
					}
				});
			},

			//清空司机信息
			truck_owner_clear(){
				this.truck_owner.tel=''
				this.truck_owner.name=''
				this.truck_owner.user_num=''
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//清空货源数据
						this.form.case_date=null
						this.form.aim_date=null
						this.form.need_truck_type=null
						this.form.out_trade_num=''
						this.form.group1_num=''
						this.form.mark=''

						this.shipper_bl.rel=''
						this.shipper_bl.cargo_name=''
						this.shipper_bl.case_addr={
							prov:'',
							city:'',
							county:'',
							addr:'',
							lat:0,
							lng:0,
							total_text:''
						}

						this.shipper_bl.aim_addr={
							prov:'',
							city:'',
							county:'',
							addr:'',
							lat:0,
							lng:0,
							total_text:''
						}

						this.cargo_weight.num=''
						this.cargo_weight.is_need_weight=''
						this.cargo_weight.is_need_weight_text=''

						this.cargo_volume.num=''
						this.cargo_volume.is_need_volume=''
						this.cargo_volume.is_need_volume_text=''

						this.truck_owner.tel=''
						this.truck_owner.name=''
						this.truck_owner.user_num=''
					}
				});
			},

			//客户线路选择
			shipper_bl_change(){
				
				//取出线路详情
				let bl_info=this.shipper_bl.original_data[this.shipper_bl.rel[1]]
				
				//写入货物名称
				this.shipper_bl.cargo_name=bl_info.cargo_name

				//写入发货地
				this.shipper_bl.case_addr.prov=bl_info.case_prov
				this.shipper_bl.case_addr.city=bl_info.case_city
				this.shipper_bl.case_addr.county=bl_info.case_county
				this.shipper_bl.case_addr.addr=bl_info.case_addr
				this.shipper_bl.case_addr.lat=bl_info.case_lat
				this.shipper_bl.case_addr.lng=bl_info.case_lng
				this.shipper_bl.case_addr.total_text=bl_info.case_prov+' / '+bl_info.case_city+' / '+bl_info.case_county+"  / "+bl_info.case_addr
			
				//写入到货地
				this.shipper_bl.aim_addr.prov=bl_info.aim_prov
				this.shipper_bl.aim_addr.city=bl_info.aim_city
				this.shipper_bl.aim_addr.county=bl_info.aim_county
				this.shipper_bl.aim_addr.addr=bl_info.aim_addr
				this.shipper_bl.aim_addr.lat=bl_info.aim_lat
				this.shipper_bl.aim_addr.lng=bl_info.aim_lng
				this.shipper_bl.aim_addr.total_text=bl_info.aim_prov+' / '+bl_info.aim_city+' / '+bl_info.aim_county+"   / "+bl_info.aim_addr

				//结算方式
				this.shipper_bl.settlement_type=bl_info.settlement_type

				//是否是双边
				this.shipper_bl.is_need_return=bl_info.is_need_return

				//运输距离
				this.shipper_bl.distance=bl_info.distance

				//是否必填重量
				this.cargo_weight.is_need_weight=bl_info.is_need_weight
				switch(bl_info.is_need_weight){
					case '1':this.cargo_weight.is_need_weight_text="必填, 请输入";break;
					case '2':this.cargo_weight.is_need_weight_text="非必填";break;
				}

				//是否必填体积
				this.cargo_volume.is_need_volume=bl_info.is_need_volume
				switch(bl_info.is_need_volume){
					case '1':this.cargo_volume.is_need_volume_text="必填, 请输入";break;
					case '2':this.cargo_volume.is_need_volume_text="非必填";break;
				}
			},

			//获取创建参数
			get_creat_para(){

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(creat_para){
					this.truck_type=creat_para.truck_type
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{
						
						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);
						
						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.truck_type=truck_type
					}
				})
			},
			
			//获取货主线路
			get_shipper_bl(){

				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_business_line',
						ctr:'shipper_bl_list_admin'
					},
					callback:(data)=>{

						//格式化车型数据
						let shipper_list={};
						let original_data={}
						
						//遍历
						for(var item of data.list){

							//原始数据
							original_data[item.id]={...item}

							//汇集货主数据
							if(!shipper_list[item.shipper_num]){
								shipper_list[item.shipper_num]={
									value:item.shipper_num,
									label:item.shipper_company_name,
									children:[]
								};
							}

							//结算方式
							var settlement_type_text=""
							switch(item.is_need_return){
								case '1':settlement_type_text="按吨数结算";break;
								case '2':settlement_type_text="按方数结算";break;
								case '3':settlement_type_text="按趟结算";break;
							}

							//是否是双边
							var is_need_return_text=""
							switch(item.is_need_return){
								case '1':is_need_return_text="双边";break;
								case '2':is_need_return_text="单边";break;
							}

							//汇集货主路线数据
							shipper_list[item.shipper_num].children.push({
								value:item.id,
								label:item.case_city+'/'+item.case_county+' ---- '+item.aim_city+'/'+item.aim_county+' ('+is_need_return_text+') ('+settlement_type_text+')',
							})
						}

						//置入货主数据
						this.shipper_bl.data=Object.values(shipper_list)//联动主数据
						this.shipper_bl.original_data=original_data//原始数据
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: 100%;
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form_bl{
		height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}

	.page_bl{
		position:relative;
		height:100%;
	}
	
</style>